import React from "react"

import { css } from "@emotion/core"

import { theme } from "../styles/theme"

const TabTitle = ({ isActive, onPress, title }) => {
  return (
    <li css={styles.container}>
      <button
        onClick={onPress}
        css={
          isActive ? [styles.tabTitle, styles.tabTitleActive] : styles.tabTitle
        }
      >
        <span
          css={
            isActive
              ? [styles.tabTitleText, styles.tabTitleTextActive]
              : styles.tabTitleText
          }
        >
          {title}
        </span>
      </button>
    </li>
  )
}

const styles = {
  tabTitle: css({
    backgroundColor: theme.colours.lightGrey,
    padding: theme.gap,
    textAlign: "center",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    border: "none",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),
  tabTitleActive: css({
    backgroundColor: theme.colours.red,
  }),
  tabTitleText: css({
    color: theme.colours.darkGrey,
    textAlign: "center",
    fontWeight: "bold",
  }),
  tabTitleTextActive: css({
    color: theme.colours.white,
  }),
}

export default TabTitle
